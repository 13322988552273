<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Vacancies/List</h4>
          <div>
            <router-link :to="can('vacancy-create') ? {name:'appVacancyCreate'} : '#'">
              <span :title="can('vacancy-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-12 col-md-4">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <label>Type</label>
                <VueMultiselect v-model="selectedType" class="" :options="typeList" :close-on-select="true" label="name"
                                track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <label>Status</label>
                <VueMultiselect v-model="selectedStatus" class="" :options="statusList" :close-on-select="true"
                                placeholder="Select status" label="name" track-by="value" :show-labels="false"
                                :allow-empty="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9">
                <input v-model="getVacanciesParams.title" class="form-control search-admin-input-element" type="text"
                       placeholder="Search by title.">
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyVacancyFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 5%">SL</th>
                    <th class="position-relative" style="width: 30%">Title</th>
                    <th class="position-relative" style="width: 20%">End Date</th>
                    <th class="position-relative" style="width: 15%">Total Vacancy</th>
                    <th class="position-relative" style="width: 10%">Type</th>
                    <th class="position-relative" style="width: 10%">STATUS</th>
                    <th class="position-relative text-right" style="width: 15%">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(vacancy, index) in vacancies" :key="index">
                    <td>
                      <span>{{ index + 1 }}</span>
                    </td>
                    <td>
                      <span>{{ vacancy.title }}</span>
                    </td>
                    <td>
                      <span>{{ vacancy.end_date ?? 'Unlimited' }}</span>
                    </td>
                    <td>
                      <span>{{ vacancy.total_vacancy ?? 'Unlimited' }}</span>
                    </td>
                    <td>
                      <span>{{ vacancy.type }}</span>
                    </td>
                    <td>
                      <button class="btn"
                              :disabled="!can('vacancy-update')"
                              :title="can('vacancy-update') ? `${vacancy.status} | Click to update` : `${vacancy.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#vacancyStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: vacancy.id, existingData: {status: vacancy.status}}">
                        <span class="badge"
                              :class="vacancy.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">{{
                            vacancy.status
                          }}</span>
                      </button>
                    </td>
                    <td class="text-right">
                      <div class="d-flex align-items-top justify-content-between">
                        <button :title="can('vacancy-view') ? 'View' : 'View Unauthenticated'"
                                :disabled="!can('vacancy-view')"
                                @click="this.viewVacancyObj = vacancy"
                                class="btn text-decoration-none cursor-pointer view-edit-delete-icon px-0"
                                data-toggle="modal"
                                data-target="#vacancyViewModal">
                          <i class="bx bx-show"></i>
                        </button>
                        <router-link :title="can('vacancy-update') ? 'Update' : 'Update Unauthenticated'"
                                     :to="can('vacancy-update') ? {name:'appVacancyEdit',params:{id:vacancy.id}} : '#'"
                                     class="text-decoration-none view-edit-delete-icon btn px-0">
                          <i class='bx bx-edit'></i>
                        </router-link>
                        <button :title="can('vacancy-delete') ? 'Delete' : 'Delete Unauthenticated'"
                                :disabled="!can('vacancy-delete')"
                                @click="this.modelIdForDelete = vacancy.id"
                                class="btn text-decoration-none cursor-pointer view-edit-delete-icon px-0"
                                data-toggle="modal"
                                data-target="#VacancyDeleteAlertModal">
                          <i class='bx bx-x'></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyVacancyFilter"/>
              </div>
            </div>
          </div>
        </div>

        <vacancy-view-modal :vacancy="viewVacancyObj"/>
        <vacancy-status-update-alert-modal :model-for-updating="modelForUpdating" model-name="vacancy"
                                           modal-name="vacancyStatusUpdateAlertModal"
                                           @confirmModelUpdating="vacancyStatusUpdating"/>


        <VacancyDeleteAlertModal :model-id="modelIdForDelete" model-name="labUser" modal-name="VacancyDeleteAlertModal"
                                 @confirmModelDeletion="confirmModelDeletion"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import VacancyViewModal from "@/views/backEnd/vacancies/includes/VacancyViewModal";
import VacancyStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import VacancyDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';

export default {
  name: "VacancyList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    VacancyViewModal,
    VacancyStatusUpdateAlertModal,
    VacancyDeleteAlertModal,
    ListPagination,
    VueMultiselect
  },
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      viewVacancyObj: {},
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedType: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },

      getVacanciesParams: {
        title: '',
        type: '',
        status: '',
        paginate: 1,
        order_by_id: 'DESC',
        pagination: '',
        page: ''
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },

      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },

      modelIdForDelete: '',
    };
  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getVacanciesParams.pagination = selectedPagination.value;
    },
    selectedType(selectedType) {
      this.getVacanciesParams.type = selectedType.value;
    },
    selectedStatus(selectedStatus) {
      this.getVacanciesParams.status = selectedStatus.value;
    },
  },
  computed: {
    ...mapGetters({

      vacancies: 'appVacancies/vacancies',
      paginateLinks: 'appVacancies/paginateLinks',
    }),

    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    statusList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },

    typeList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Full time'
        },
        {
          value: 1,
          name: 'Part time'
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getVacancies: 'appVacancies/getVacancies',
      resetVacancies: 'appVacancies/resetVacancies',
      putVacancyOnList: 'appVacancies/putVacancyOnList',
      deleteVacancyOnList: 'appVacancies/deleteVacancyOnList',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getVacancyList() {
      await this.getVacancies(this.getVacanciesParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },

    async applyVacancyFilter(pageNumber) {
      this.loader(true);
      this.getVacanciesParams.page = pageNumber;
      await this.getVacancyList();
      this.loader(false);
    },

    async vacancyStatusUpdating(vacancyStatusUpdating) {
      await this.vacancyStatusUpdatingOnList(vacancyStatusUpdating);
    },

    async vacancyStatusUpdatingOnList(vacancyStatusUpdating) {
      let dataObj = {
        id: vacancyStatusUpdating.modelForUpdating.modelId,
        data: {
          status: vacancyStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }

      this.putVacancyOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Vacancy status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async confirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleVacancyOnList(confirmModelDeletion.modelId);
    },

    async deleteSingleVacancyOnList(id) {
      this.deleteVacancyOnList(id).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Vacancy Deleted",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      })
    }
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getVacancyList();
    this.loader(false);
  },

  async beforeUnmount() {
    await this.resetVacancies();
  },
}
</script>

<style scoped>

.view-edit-delete-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5A8DEE;
}

.search-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>

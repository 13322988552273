<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Blogs/List</h4>
          <div>
            <router-link :to="can('blog-create') ? {name:'appBlogCreate'} : '#'">
              <span :title="can('blog-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Blog Category</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedBlogCategory" class="" :options="blogCategoryList"
                                :close-on-select="true"
                                placeholder="Select Blog Category Name" label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Is Featured</label>
                <VueMultiselect v-model="selectedIsFeatured" class="" :options="featuredList" :close-on-select="true" label="name"
                                track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect v-model="selectedStatus" class="" :options="statusList" :close-on-select="true"
                                placeholder="Select status" label="name" track-by="value" :show-labels="false"
                                :allow-empty="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9">
                <input v-model="getBlogsParams.title" class="form-control search-admin-input-element" type="text"
                       placeholder="Search By Blog Title">
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyBlogFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 5%">SL</th>
                    <th class="position-relative" style="width: 10%">Image</th>
                    <th class="position-relative" style="width: 30%">Title</th>
                    <th class="position-relative" style="width: 30%">Blog Category</th>
                    <th class="position-relative" style="width: 5%">Is Featured</th>
                    <th class="position-relative" style="width: 5%">STATUS</th>
                    <th class="position-relative text-right" style="width: 15%">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(blog, index) in blogs" :key="index">
                    <td>
                      <span>{{ index + 1 }}</span>
                    </td>
                    <td>
                      <img :src="blog.thumbnail" class="img-fluid" alt="blog image" style="height: 3rem;">
                    </td>
                    <td>
                      <span>{{ blog.title }}</span>
                    </td>
                    <td>{{ blog.blogCategory?.name ?? '' }}</td>
                    <td>
                      <button class="btn"
                              :disabled="!can('blog-update')"
                              :title="can('blog-update') ? `${blog.is_featured} | Click to update` : `${blog.is_featured} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#blogIsFeaturedUpdateAlertModal"
                              @click="modelForUpdating = {modelId: blog.id, existingData: {is_featured: blog.is_featured == 'Yes' ? 1 : 0}}">
                        <span class="badge"
                              :class="blog.is_featured == 'Yes' ? 'badge-light-success' : 'badge-light-danger'">{{
                            blog.is_featured
                          }}</span>
                      </button>
                    </td>
                    <td>
                      <button class="btn"
                              :disabled="!can('blog-update')"
                              :title="can('blog-update') ? `${blog.status} | Click to update` : `${blog.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#blogStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: blog.id, existingData: {status: blog.status}}">
                        <span class="badge"
                              :class="blog.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">{{
                            blog.status
                          }}</span>
                      </button>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <div>
                          <router-link
                              :to="can('blog-view') ? {name: 'appBlogView',params: { id: blog.id }} : '#'"
                              :title="can('blog-view') ? 'View' : 'View Forbidden'"
                              class="cursor-pointer view-edit-delete-icon"
                              :style="{ height: '18px' }">
                            <i class="bx bx-show"></i>
                          </router-link>
                        </div>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">
                            <router-link :to="{name:'appBlogEdit', params:{id:blog.id}}" :disabled="!can('blog-update')"
                                    :title="can('blog-update') ? 'Update' : 'Update Forbidden'"
                                     class="dropdown-item">Update
                            </router-link>
                            <button :disabled="!can('blog-delete')" @click="modelIdForDelete = blog.id" data-toggle="modal"
                                    :title="can('blog-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-target="#deleteBlogModal" class="dropdown-item">Delete
                            </button>

                          </div>
                        </div>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyBlogFilter"/>
              </div>
            </div>
          </div>
        </div>

        <BlogModal :blog="this.blog"/>
        <blog-status-update-alert-modal :model-for-updating="modelForUpdating" model-name="blog"
                                           modal-name="blogStatusUpdateAlertModal"
                                           @confirmModelUpdating="blogStatusUpdating"/>

        <BlogIsFeaturedUpdateAlertModal :model-for-updating="modelForUpdating" model-name="blog"
                                        modal-name="blogIsFeaturedUpdateAlertModal"
                                        @confirmModelUpdating="blogIsFeaturedUpdating"/>


        <BlogDeleteAlertModal modal-name="deleteBlogModal" model-name="blog" :model-id="modelIdForDelete"
                                      @confirmModelDeletion="onConfirmModelDeletion"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import BlogStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import BlogIsFeaturedUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';
import BlogDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";

export default {
  name: "BlogList.vue",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    BlogStatusUpdateAlertModal,
    BlogIsFeaturedUpdateAlertModal,
    BlogDeleteAlertModal,
    ListPagination,
    VueMultiselect
  },
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      viewBlogObj: {},
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedIsFeatured: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      selectedBlogCategory: {
        value: '',
        name: 'Any'
      },
      getBlogsParams: {
        blog_category_id: '',
        title: '',
        is_featured: '',
        status: '',
        paginate: 1,
        order_by_id: 'DESC',
        pagination: '',
        page: '',
        with_relation: ['blogCategory', 'blogTags', 'media'],
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },

      getBlogCategoriesParams: {
        status: 1,
        order_by_name: 'ASC',
      },
      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },

      modelIdForDelete: '',
    };
  },

  watch: {
    selectedPagination(selectedPagination) {
      this.getBlogsParams.pagination = selectedPagination.value;
    },
    selectedBlogCategory(selectedBlogCategory) {
      this.getBlogsParams.blog_category_id = selectedBlogCategory.value;
    },
    selectedIsFeatured(selectedIsFeatured) {
      this.getBlogsParams.is_featured = selectedIsFeatured.value;
    },
    selectedStatus(selectedStatus) {
      this.getBlogsParams.status = selectedStatus.value;
    },
  },

  computed: {
    ...mapGetters({

      blogs: 'appBlogs/blogs',
      paginateLinks: 'appBlogs/paginateLinks',
    }),

    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    blogCategoryList() {
      let blogCategories = this.$store.getters['appBlogCategories/blogCategories'].map((blogCategory) => {
        let id = blogCategory.id;
        let name = blogCategory.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'Any'}, ...blogCategories];
    },

    statusList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },

    featuredList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'No'
        },
        {
          value: 1,
          name: 'Yes'
        },
      ]
    },
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getBlogCategories: 'appBlogCategories/getBlogCategories',
      getBlogs: 'appBlogs/getBlogs',
      resetBlogs: 'appBlogs/resetBlogs',
      putBlogOnList: 'appBlogs/putBlogOnList',
      deleteBlogOnList: 'appBlogs/deleteBlogOnList',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getBlogCategoryList() {
      await this.getBlogCategories(this.getBlogCategoriesParams);
    },

    async getBlogList() {
      await this.getBlogs(this.getBlogsParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },

    async applyBlogFilter(pageNumber) {
      this.loader(true);
      this.getBlogsParams.page = pageNumber;
      await this.getBlogList();
      this.loader(false);
    },

    async getSingleBlog(id) {
      let index = this.blogs.findIndex(item => item.id === id);
      this.blog = index !== -1 ? this.blogs[index] : {}
    },

    async blogStatusUpdating(blogStatusUpdating) {
      console.warn(blogStatusUpdating, "blogStatusUpdating")
      await this.blogStatusUpdatingOnList(blogStatusUpdating);
    },

    async blogIsFeaturedUpdating(blogIsFeaturedUpdating) {
      await this.blogIsFeaturedUpdatingOnList(blogIsFeaturedUpdating);
    },

    async blogStatusUpdatingOnList(blogStatusUpdating) {
      let dataObj = {
        id: blogStatusUpdating.modelForUpdating.modelId,
        data: {
          status: blogStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }

      this.putBlogOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Blog status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async blogIsFeaturedUpdatingOnList(blogIsFeaturedUpdating) {
      let dataObj = {
        id: blogIsFeaturedUpdating.modelForUpdating.modelId,
        data: {
          is_featured: blogIsFeaturedUpdating.modelForUpdating.existingData.is_featured,
        }
      }

      this.putBlogOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Blog is featured updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async deleteSingleBlogOnList(id) {
      this.deleteBlogOnList(id).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Blog Deleted",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      })
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleBlogOnList(confirmModelDeletion.modelId);
    },

  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getBlogCategoryList();
    await this.getBlogList();
    this.loader(false);
  },

  async beforeUnmount() {
    await this.resetBlogs();

  },

}
</script>



<style scoped>

.view-edit-delete-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5A8DEE;
}

.search-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>
<template>
  <div class="modal fade " id="vacancyViewModal" tabindex="-1" role="dialog" aria-labelledby="vacancyViewModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Vacancy Details</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="text-center">{{ vacancy.title }}</h4>
          <div class="text-center">
            <span class="badge mr-1"
                  :class="vacancy.status === 'Active' ? 'badge-success' : 'badge-danger'">{{ vacancy.status }}</span>
            <span v-if="vacancyExpired" class="badge badge-warning">Expired</span>
          </div>
          <div class="mt-2">
            <h6 class="text-center">Short Description</h6>
            <hr/>
            <p>{{ vacancy.short_description }}</p>
          </div>
          <div>
            <h6 class="text-center">Description</h6>
            <hr/>
            <p v-html="vacancy.description"></p>
          </div>
          <div>
            <h6 class="text-center">Other Info</h6>
            <hr/>
            <p>
              <strong>Location: </strong>
              <span :class="vacancy.location ? '' : 'text-danger'">
                {{ vacancy.location ?? 'Not Provided' }}
              </span>
            </p>
            <p>
              <strong>End Date: </strong>
              <span>{{ vacancy.end_date ?? 'Unlimited' }}</span>
            </p>
            <p>
              <strong>Experience: </strong>
              <span :class="vacancy.experience ? '' : 'text-danger'">
                {{ vacancy.experience ?? 'Not Provided' }}
              </span>
            </p>
            <p>
              <strong>Working Hour: </strong>
              <span :class="vacancy.working_hour ? '' : 'text-danger'">
                {{ vacancy.working_hour ?? 'Not Provided' }}
              </span>
            </p>
            <p>
              <strong>Working Day: </strong>
              <span :class="vacancy.working_day ? '' : 'text-danger'">
                {{ vacancy.working_day ?? 'Not Provided' }}
              </span>
            </p>
            <p>
              <strong>Total Vacancy: </strong>
              <span :class="vacancy.total_vacancy ? '' : 'text-danger'">
                {{ vacancy.total_vacancy ?? 'Not Provided' }}
              </span>
            </p>
            <p>
              <strong>Type: </strong>
              <span :class="vacancy.type ? '' : 'text-danger'">
                {{ vacancy.type ?? 'Not Provided' }}
              </span>
            </p>
            <p>
              <strong>Apply Email Address: </strong>
              <span :class="vacancy.apply_email_address ? '' : 'text-danger'">
                {{ vacancy.apply_email_address ?? 'Not Provided' }}
              </span>
            </p>
            <p>
              <strong>Email Subject: </strong>
              <span :class="vacancy.email_subject ? '' : 'text-danger'">
                {{ vacancy.email_subject ?? 'Not Provided' }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "VacancyViewModal",
  props: ['vacancy'],
  computed: {
    vacancyExpired() {
      if (this.vacancy.end_date) {
        let endDate = Date.parse(this.vacancy.end_date);
        let currentDate = new Date();
        if (endDate < currentDate) return true;
      }

      return false;
    }
  }
}
</script>

<style scoped>

</style>